import type DataGridControl from 'o365.controls.DataGrid.ts';
import type DataColumn from 'o365.controls.DataGrid.Column.ts';
import { createPopper } from 'popper';

export default class DataGridHeaderr {
    private _getDataGridControl: () => DataGridControl;
    private _getGroup: (uid?: string | null) => DataColumn | undefined;
    private _headerContainer: HTMLElement | undefined;
    private _activeEvents: { name: string, listener: (e: any) => void, target: HTMLElement }[] = [];
    /** A map of mounted filter cells */
    private _filterCells: Record<number, any> = {};

    private _cleanupTokens: (() => void)[] = [];

    constructor(pOptions: {
        dataGridControl: DataGridControl,
        getGroup: typeof DataGridHeaderr.prototype._getGroup
    }) {
        this._getDataGridControl = () => pOptions.dataGridControl;
        this._getGroup = pOptions.getGroup;
    }

    /** Handler for header mounted hook */
    handleMounted(pOptions: {
        headerContainer: typeof DataGridHeaderr.prototype._headerContainer
    }) {
        if (!pOptions.headerContainer) { return; }
        this._headerContainer = pOptions.headerContainer;

        let isTouch = false;
        try {
            isTouch = matchMedia('screen and (pointer: coarse)')?.matches ?? false;
        } catch (ex) {
            console.warn(ex);
        }

        const mouseDownEvent = (e: MouseEvent) => this._handleMoveStart(e);
        this._headerContainer.addEventListener('mousedown', mouseDownEvent);
        this._activeEvents.push({ name: 'mousedown', listener: mouseDownEvent, target: this._headerContainer });
        if (isTouch) {
            const touchStartEvent = (e: TouchEvent) => this._handleMoveStart(e);
            this._headerContainer.addEventListener('touchstart', touchStartEvent, { passive: true });
            this._activeEvents.push({ name: 'touchstart', listener: touchStartEvent, target: this._headerContainer });
        }
        const gridControl = this._getDataGridControl();
        if (gridControl.dataObject) {
            this._cleanupTokens.push(gridControl.dataObject.on('SortOrderChanged', () => {
                const dataGridControl = this._getDataGridControl();
                let currentSortOrder = dataGridControl.dataObject!.recordSource.getSortOrder() ?? [];
                dataGridControl.dataColumns.columns.filter(x => x.sort).forEach(col => {
                    col.sortOrder = null;
                    col.sort = null;
                });
                currentSortOrder.forEach((order, index) => {
                    const sortField = Object.keys(order)[0] as string;
                    dataGridControl.dataColumns.columns.filter(x => x.field === sortField || x.sortField === sortField).forEach(col => {
                        if (currentSortOrder.length > 1) {
                            col.sortOrder = index + 1;
                        }
                        col.sort = order[sortField];
                    });
                });
            }));
        }
    }

    destroy() {
        this._activeEvents.forEach((event) => {
            event.target.removeEventListener(event.name, event.listener);
        });
        this._cleanupTokens.forEach(ct => ct());
        this._cleanupTokens.splice(0, this._cleanupTokens.length);
    }

    /** Set sort direction for column */
    setSort(pColId: string, pSortDirection?: 'asc' | 'desc', pAppend = false) {
        const dataGridControl = this._getDataGridControl();
        const column = dataGridControl.dataColumns.getColumn(pColId);
        if (column == null || !column.sortable) { return; }
        const sortField = column.sortField ?? column.field;
        if (dataGridControl.dataObject) {
            const currentField = dataGridControl.dataObject.fields[sortField]?.item;
            if (currentField == null) { return; }
            currentField.sortDirection = pSortDirection ?? currentField.sortDirection === 'asc' ? 'desc' : 'asc';
            const newSortOrder = {
                [currentField.name]: currentField.sortDirection
            };
            let currentSortOrder = dataGridControl.dataObject.recordSource.getSortOrder() ?? [];
            if (pAppend) {
                if (column.sort) {
                    currentSortOrder.forEach((item, index) => {
                        const vTmp = Object.keys(item);
                        if (vTmp[0] === sortField) {
                            currentSortOrder[index] = newSortOrder;
                        }
                    });
                } else {
                    currentSortOrder.push(newSortOrder);
                }
            } else {
                currentSortOrder = [newSortOrder];
            }
            dataGridControl.dataObject.recordSource.setSortOrder(currentSortOrder, true, true);
            dataGridControl.dataColumns.columns.filter(x => x.sort).forEach(col => {
                col.sortOrder = null;
                col.sort = null;
            });

            currentSortOrder.forEach((order, index) => {
                const sortField = Object.keys(order)[0] as string;
                dataGridControl.dataColumns.columns.filter(x => x.field === sortField || x.sortField === sortField).forEach(col => {
                    if (currentSortOrder.length > 1) {
                        col.sortOrder = index + 1;
                    }
                    col.sort = order[sortField];
                });
            });
            dataGridControl.dataObject.load();
        } else {
            const orgSort = column.sort;
            switch (column.sort) {
                case 'asc':
                    column.sort = 'desc';
                    break;
                case 'desc':
                default:
                    column.sort = 'asc';
            }
            if (pAppend) {
                if (orgSort == null) {
                    const sortingCols = dataGridControl.dataColumns.columns.filter(x => {
                        if (x.sort != null) {
                            if (x.sortOrder == null) { x.sortOrder = 1; }
                            return true;
                        } else {
                            return false;
                        }
                    });
                    column.sortOrder = sortingCols.length;
                }
            } else {
                dataGridControl.dataColumns.columns.forEach(x => x.sortOrder = null);
                dataGridControl.dataColumns.columns.forEach(x => {
                    if (x.colId !== column.colId) {
                        x.sort = null;
                    }
                });
            }
            (dataGridControl.utils as any).applySort();
        }
    }

    /**
     * Try to focus first valid filter cell. If pField is provided then 
     * will first look for a valid column with that field name
     */
    focusFirstFilterCell(pField?: string) {
        let index: number | null = null;
        if (pField) {
            index = this._getDataGridControl().dataColumns.columns.findIndex(col => (col.field === pField || col.colId === pField) && !col.hide && col.filter != null);
        }
        if (index == null || index === -1) {
            index = this._getDataGridControl().dataColumns.columns.findIndex(col => !col.colId.startsWith('o365_') && !col.hide && col.filter != null);
        }
        if (index !== -1) {
            this.focusFilterCell(index);
        }
    }

    /** Try to focus the filter cell of a column */
    focusFilterCell(pColIndex: number) {
        this._filterCells[pColIndex]?.focusInput();
    }

    /** Used by the Header component to populate the filter cells map */
    _setFilterCellRef(pFilterRef: any, pIndex: number) {
        this._filterCells[pIndex] = pFilterRef;
    }

    setColumnPin(column: DataColumn, pinned: 'left'|'right'|''|null) {
        if (column.pinned === pinned) { return; }
        const dataGridControl = this._getDataGridControl();
        let pinnedIndex = -1;

        const getLeftIndex = () => {
            dataGridControl.dataColumns.columns.forEach((col, index) => {
                if (col.pinned === 'left') { pinnedIndex = index; }
            });
        };

        const getRightIndex = () => {
            dataGridControl.dataColumns.columns.every((col, index) => {
                if (col.pinned === 'right') {
                    pinnedIndex = index;
                    return false;
                } else {
                    return true;
                }
            });
        };

        switch (pinned) {
            case 'left':
                getLeftIndex();
                dataGridControl.dataColumns.setColumnOrder(column, pinnedIndex + 1)
                break;
            case 'right':
                getRightIndex();
                if (pinnedIndex === -1) { pinnedIndex = dataGridControl.dataColumns.columns.length }
                dataGridControl.dataColumns.setColumnOrder(column, pinnedIndex - 1);
                break;
            default:
                if (column.pinned === 'left') {
                    getLeftIndex();
                    if (pinnedIndex === -1) { pinnedIndex = 0; }
                } else {
                    getRightIndex();
                    if (pinnedIndex === -1) { pinnedIndex = dataGridControl.dataColumns.columns.length }
                    pinnedIndex = dataGridControl.dataColumns.columns[pinnedIndex].field === 'o365_Action' ? pinnedIndex - 1 : pinnedIndex;
                }
                dataGridControl.dataColumns.setColumnOrder(column, pinnedIndex - 1);
        }
        column.pinned = pinned;
        dataGridControl.dataColumns.updateColumnArrays();
    }

    private _handleMoveStart(event: MouseEvent | TouchEvent) {
        const item = event.target as HTMLElement | undefined;
        if (!item?.classList?.contains('o365-sizer')) { return; }
        const isTouchEvent = ('touches' in event);
        const dataGridControl = this._getDataGridControl();
        let lockResolve: Function | null = null;
        const layoutSaveLock = new Promise<void>((res) => {
            lockResolve = res;
        });
        dataGridControl.layoutSaveLocks.push(layoutSaveLock);

        const generateGetBoundingClientRect = (x: number, y: number) => {
            return () => ({
                width: 0,
                height: 0,
                top: y,
                right: x,
                bottom: y,
                left: x,
            });
        };

        const resizeTooltip = document.createElement('span');
        resizeTooltip.classList.add('text-bg-primary', 'px-1', 'rounded-1')
        resizeTooltip.style.zIndex = '1000';
        document.body.append(resizeTooltip);

        const setTooltipValue = (value: string | number, flexValue?: number) => {
            resizeTooltip.innerText = flexValue ? `${value}px (+${Math.round(flexValue)}px)` : `${value}px`;
        };

        const popperVirtualElement = {
            getBoundingClientRect: generateGetBoundingClientRect(this._getPropertyFromEvent(event, 'x'), this._getPropertyFromEvent(event, 'y'))
        };

        const popperInstance = createPopper(popperVirtualElement, resizeTooltip, {
            placement: 'bottom-end',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [-5, 10]
                    }
                }
            ],
        });

        const containerRef = dataGridControl.container;

        if (containerRef) {
            containerRef.classList.add('resizing');
            containerRef.style.cursor = 'col-resize';
            containerRef.style.userSelect = 'none';
        }

        const columns = dataGridControl.dataColumns.columns;

        const column = item.getAttribute('o365-header-group')
            ? this._getGroup(item.getAttribute('o365-header-group'))
            : columns.find(col => col.colId === item.getAttribute('o365-field'));

        if (!column) { return; }

        const headerCell = (event.target as HTMLElement)?.closest('.o365-header-cell') as HTMLElement
        let flexWidthElement: HTMLElement | null = null;
        let flexIndicators: [HTMLElement, HTMLElement] | null = null;
        if (column.flexWidth && headerCell) {
            headerCell.classList.add('resizing')
            flexWidthElement = document.createElement('div');
            flexWidthElement.classList.add('d-flex', 'position-absolute', 'w-100', 'h-100', 'overflow-hidden')
            const minWidthIndicator = document.createElement('div');
            minWidthIndicator.classList.add('h-100', 'bg-danger', 'd-flex')
            minWidthIndicator.style.minWidth = column.minWidth + 'px';
            minWidthIndicator.style.maxWidth = column.minWidth + 'px';
            flexIndicators = [
                document.createElement('div'),
                document.createElement('div'),
            ];
            flexIndicators[0].classList.add('h-100', 'bg-warning', 'd-flex')
            flexIndicators[1].classList.add('h-100', 'w-100', 'bg-primary', 'd-flex')
            const normalWidthSpan = document.createElement('span');
            const flexWidthSpan = document.createElement('span');
            normalWidthSpan.innerText = 'width';
            normalWidthSpan.classList.add('text-white', 'align-self-center', 'text-truncate', 'ms-2');
            flexWidthSpan.classList.add('text-white', 'align-self-center', 'text-truncate', 'ms-2');
            flexWidthSpan.innerText = 'flex-width';
            flexIndicators[0].append(normalWidthSpan);
            flexIndicators[1].append(flexWidthSpan);
            flexWidthElement.append(minWidthIndicator);
            flexWidthElement.append(flexIndicators[0]);
            flexWidthElement.append(flexIndicators[1]);
            headerCell.append(flexWidthElement);
            headerCell.style.padding = 'unset';
        }

        const setIndicatorWidth = (value: number) => {
            window.requestAnimationFrame(() => {
                if (flexIndicators && flexIndicators[0]) {
                    flexIndicators[0].style.minWidth = (value - column.minWidth) + 'px';
                }
            });
        }

        setIndicatorWidth(column.width);
        let nextColumn: DataColumn | null = null;
        if (column.flexWidth) {
            nextColumn = columns.find(col => col.order > column.order && column.pinned === col.pinned && col.flexWidth && !col.hide) ?? null;
        }

        setTooltipValue(column.width, column.widthAdjustment);

        const onMove = (e: MouseEvent | TouchEvent) => {
            window.requestAnimationFrame(() => {
                if (item.parentElement == null) { return; }
                let width: number | null = null;
                const clientX = this._getPropertyFromEvent(e, 'clientX');
                if (clientX == null) { return; }

                if (dataGridControl.props.useLeftColumnSizers || (column.pinned === 'right' && !dataGridControl.props.disableRightPinnedOffset)) {
                    width = (item.parentElement.getBoundingClientRect().x + item.parentElement.getBoundingClientRect().width) - clientX;
                } else {
                    width = clientX - item.parentElement.getBoundingClientRect().x;
                }

                width = parseInt(`${width}`) - column.widthAdjustment;
                width = Math.round(width);
                let shouldUpdateWidth = true;

                if (shouldUpdateWidth) {
                    if (width >= column.minWidth) {
                        column.width = width;
                        setTooltipValue(column.width, column.widthAdjustment);
                        setIndicatorWidth(width);
                    } else if (column.minWidth !== column.width) {
                        column.width = column.minWidth;
                        setTooltipValue(column.width, column.widthAdjustment);
                    } else {
                        if (nextColumn) {
                            if (width < column.width) {
                                // decrease this flex, increase sibling
                                if (column.flexWidth >= 1) {
                                    const difference = column.width - width;
                                    const unusedWidth = dataGridControl.dataColumns.unusedWidth;
                                    const flexAdjustment = Math.round((column.widthAdjustment - difference) / unusedWidth * 100);
                                    if (flexAdjustment >= 1) {
                                        const flexDiff = column.flexWidth - flexAdjustment;
                                        column.flexWidth = flexAdjustment;
                                        nextColumn.flexWidth += flexDiff;
                                        column.widthAdjustment -= difference;
                                        nextColumn.widthAdjustment += difference;
                                        setTooltipValue(column.width, column.widthAdjustment);
                                        shouldUpdateWidth = false;
                                    }
                                }
                            } else {
                                // increase this flex, decrease sibling
                                if (nextColumn.flexWidth >= 1) {
                                    const difference = width - column.width;
                                    const unusedWidth = dataGridControl.dataColumns.unusedWidth;
                                    const flexAdjustment = Math.round((column.widthAdjustment + difference) / unusedWidth * 100);
                                    const flexDiff = flexAdjustment - column.flexWidth;
                                    if (nextColumn.flexWidth - flexDiff >= 1) {
                                        column.flexWidth = flexAdjustment;
                                        nextColumn.flexWidth -= flexDiff;
                                        column.widthAdjustment += difference;
                                        nextColumn.widthAdjustment -= difference;
                                        setTooltipValue(column.width, column.widthAdjustment);
                                        shouldUpdateWidth = false;
                                    }
                                }
                            }
                        }
                    }
                }
                if (dataGridControl.setViewPortWidth) { dataGridControl.setViewPortWidth(); }

                if (!shouldUpdateWidth) {
                    dataGridControl.dataColumns.updateWidths();
                }

                popperVirtualElement.getBoundingClientRect = generateGetBoundingClientRect(this._getPropertyFromEvent(e, 'x'), this._getPropertyFromEvent(e, 'y'));
                popperInstance.update();
            });
        };

        document.addEventListener(isTouchEvent ? 'touchmove' : 'mousemove', onMove);
        const onMoveEnd = () => {
            const containerRef = dataGridControl.container;
            if (containerRef) {
                containerRef.classList.remove('resizing');
                containerRef.style.cursor = '';
                containerRef.style.userSelect = '';
            }
            document.removeEventListener(isTouchEvent ? 'touchmove' : 'mousemove', onMove);
            document.removeEventListener(isTouchEvent ? 'touchend' : 'mouseup', onMoveEnd);
            popperInstance.destroy();
            resizeTooltip.remove();
            if (flexWidthElement) {
                window.requestAnimationFrame(() => {
                    flexWidthElement?.remove();
                    headerCell.classList.remove('resizing');
                    headerCell.style.padding = '';
                });
            }
            if (lockResolve) { lockResolve(); }
        };

        document.addEventListener(isTouchEvent ? 'touchend' : 'mouseup', onMoveEnd);
        event.preventDefault();
        event.stopPropagation();
    }

    private _getPropertyFromEvent<T extends MouseEvent | TouchEvent>(event: T, property: 'x' | 'y' | 'clientX' | 'clientY') {
        if ('touches' in event) {
            switch (property) {
                case 'x':
                    property = 'clientX';
                    break;
                case 'y':
                    property = 'clientY';
                    break;
            }
            return event.touches[0][property];
        } else {
            return event[property];
        }
    }
}